import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import Cloud from "../svg-icons/Cloud";
import Triangle from "../svg-icons/Triangle";
import GlowBG from "../svg-icons/GlowBG";
import { Link } from "react-router-dom";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const PrivacyPolicy = ({ translations, lang, darkMode }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className="journey_holder upper_slide"
          style={{ borderBottom: darkMode && "1px solid #262626" }}
        >
          <Grid item sm={12} md={4} className="hideForTabAndMobileViewOnly">
            <h3 className={`subtitle mb10 ${darkMode && "white"}`}>
              {translations.pageTitles.privacyPolicypageTitle}
            </h3>
          </Grid>
          <Grid item sm={12} md={8}>
            <Grid
              container
              justifyContent={{ md: "start", lg: "end" }}
              alignItems="center"
            >
              <Grid
                item
                xs="auto"
                className="journeyText"
                component={Link}
                to={"/"}
              >
                {translations.pageTitles.HomepageTitle}
              </Grid>
              <Grid item xs="auto">
                &nbsp; &nbsp;{" "}
                <KeyboardArrowRightIcon
                  style={{ color: "#2B2B2B", fontSize: "15px" }}
                />{" "}
                &nbsp; &nbsp;
              </Grid>

              <Grid item xs="auto" className="journeyText">
                {translations.pageTitles.privacyPolicypageTitle}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="section_style mt90">
          <Cloud
            lang={lang}
            color={darkMode && "#10214B"}
            myClass="top_section_cloud1 hide_for_tab_and_smaller_view"
            // rtlClass="top_cloud1_for_rtl"
          />

          <Triangle
            lang={lang}
            color={darkMode ? "#10214B" : "white"}
            myClass="top_section_triangle1 hide_for_tab_and_smaller_view"
            // rtlClass="top_triangle2_for_rtl"
          />
          <GlowBG
            lang={lang}
            color={darkMode ? "#005DB2" : "#113CFC"}
            myClass="section_top_GlowBG1"
            // rtlClass="top_GlowBG1_for_rtl"
          />
          <GlowBG
            lang={lang}
            color={darkMode && "#005DB2"}
            myClass="section_top_GlowBG2"
            // rtlClass="top_GlowBG2_for_rtl"
          />
          <div className="upper_slide">
            <Container maxWidth="lg">
              <h1
                className={`title_large2 mb8 center ${darkMode && "lightBlue"}`}
              >
                {translations.privacyPolicy.title}
              </h1>
              <p
                className="text_body_small_regular fw400 center mb50"
                style={{ textTransform: "uppercase" }}
              >
                {translations.privacyPolicy.subtitle}
              </p>
              {/* <div className="detail_text_container"> */}
              <p
                className={`text_body_medium fw400 mb30 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.details}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.privacyPolicy.informationWeCollectTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.informationWeCollectDetails1}
              </p>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.informationWeCollectDetails2}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.privacyPolicy.howWeUseYourInformationTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.howWeUseYourInformationDetails1}
              </p>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.howWeUseYourInformationDetails2}
              </p>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.howWeUseYourInformationDetails3}
              </p>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.howWeUseYourInformationDetails4}
              </p>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.howWeUseYourInformationDetails5}
              </p>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.howWeUseYourInformationDetails6}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.privacyPolicy.disclosureOfYourInformationTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.disclosureOfYourInformationDetails1}
              </p>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.disclosureOfYourInformationDetails2}
              </p>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.disclosureOfYourInformationDetails3}
              </p>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.disclosureOfYourInformationDetails4}
              </p>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.disclosureOfYourInformationDetails5}
              </p>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.disclosureOfYourInformationDetails6}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.privacyPolicy.securityOfYourInformationTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.securityOfYourInformationDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.privacyPolicy.yourChoicesTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.yourChoicesDetails1}
              </p>
              <p
                className={`text_body_medium fw400 mb8 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.yourChoicesDetails2}
              </p>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.yourChoicesDetails3}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.privacyPolicy.childrensPrivacyTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.childrensPrivacyDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.privacyPolicy.changesToThisPrivacyPolicyTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.changesToThisPrivacyPolicyDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.privacyPolicy.contactUsTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.contactUsDetails}
              </p>

              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.privacyPolicy.footerText}
              </p>
            </Container>
            {/* </div> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
