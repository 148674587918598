import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import Cloud from "../svg-icons/Cloud";
import Triangle from "../svg-icons/Triangle";
import GlowBG from "../svg-icons/GlowBG";
import { Link } from "react-router-dom";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const TermsOfUse = ({ translations, lang, darkMode }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className="journey_holder upper_slide"
          style={{ borderBottom: darkMode && "1px solid #262626" }}
        >
          <Grid item sm={12} md={4} className="hideForTabAndMobileViewOnly">
            <h3 className={`subtitle mb10 ${darkMode && "white"}`}>
              {translations.pageTitles.termsOfUsepageTitle}
            </h3>
          </Grid>
          <Grid item sm={12} md={8}>
            <Grid
              container
              justifyContent={{ md: "start", lg: "end" }}
              alignItems="center"
            >
              <Grid
                item
                xs="auto"
                className="journeyText"
                component={Link}
                to={"/"}
              >
                {translations.pageTitles.HomepageTitle}
              </Grid>
              <Grid item xs="auto">
                &nbsp; &nbsp;{" "}
                <KeyboardArrowRightIcon
                  style={{ color: "#2B2B2B", fontSize: "15px" }}
                />{" "}
                &nbsp; &nbsp;
              </Grid>

              <Grid item xs="auto" className="journeyText">
                {translations.pageTitles.termsAndConditions}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="section_style mt90">
          <Cloud
            lang={lang}
            color={darkMode && "#10214B"}
            myClass="top_section_cloud1 hide_for_tab_and_smaller_view"
            // rtlClass="top_cloud1_for_rtl"
          />

          <Triangle
            lang={lang}
            color={darkMode ? "#10214B" : "white"}
            myClass="top_section_triangle1 hide_for_tab_and_smaller_view"
            // rtlClass="top_triangle2_for_rtl"
          />
          <GlowBG
            lang={lang}
            color={darkMode ? "#005DB2" : "#113CFC"}
            myClass="section_top_GlowBG1"
            // rtlClass="top_GlowBG1_for_rtl"
          />
          <GlowBG
            lang={lang}
            color={darkMode && "#005DB2"}
            myClass="section_top_GlowBG2"
            // rtlClass="top_GlowBG2_for_rtl"
          />
          <div className="upper_slide">
            <Container maxWidth="lg">
              <h1
                className={`title_large2 mb8 center ${darkMode && "lightBlue"}`}
              >
                {translations.termsOfUse.title}
              </h1>
              <p
                className="text_body_small_regular fw400 center mb50"
                style={{ textTransform: "uppercase" }}
              >
                {translations.termsOfUse.subtitle}
              </p>
              {/* <div className="detail_text_container"> */}
              <p
                className={`text_body_medium fw400 mb30 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.details}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.acceptanceOfTermsTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.acceptanceOfTermsDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.useOfTheWebsiteTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.useOfTheWebsiteDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.intellectualPropertyTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.intellectualPropertyDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.privacyTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.privacyDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.userConductTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb6 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.userConductDetails1}
              </p>
              <p
                className={`text_body_medium fw400 mb6 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.userConductDetails2}
              </p>
              <p
                className={`text_body_medium fw400 mb6 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.userConductDetails3}
              </p>
              <p
                className={`text_body_medium fw400 mb6 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.userConductDetails4}
              </p>
              <p
                className={`text_body_medium fw400 mb6 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.userConductDetails5}
              </p>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.userConductDetails6}
              </p>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.userConductDetails7}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.disclaimerOfWarrantiesTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.disclaimerOfWarrantiesDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.limitationOfLiabilityTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.limitationOfLiabilityDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.indemnificationTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.indemnificationDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.changesToTermsTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.changesToTermsDetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.governingLawTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.governingLawdetails}
              </p>
              <h4
                className={`title_semibold_medium mb8 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.termsOfUse.contactUsTitle}
              </h4>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.contactUsDetails}
              </p>
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {translations.termsOfUse.footerText}
              </p>
            </Container>
            {/* </div> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TermsOfUse;
