import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import Cloud from "../svg-icons/Cloud";
import Triangle from "../svg-icons/Triangle";
import GlowBG from "../svg-icons/GlowBG";
import { Link } from "react-router-dom";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Circle2 from "../svg-icons/Circle2";

const Sitemap = ({ translations, lang, darkMode }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className="journey_holder upper_slide"
          style={{ borderBottom: darkMode && "1px solid #262626" }}
        >
          <Grid item sm={12} md={4} className="hideForTabAndMobileViewOnly">
            <h3 className={`subtitle mb10 ${darkMode && "white"}`}>
              {translations.pageTitles.SitemappageTitle}
            </h3>
          </Grid>
          <Grid item sm={12} md={8}>
            <Grid
              container
              justifyContent={{ md: "start", lg: "end" }}
              alignItems="center"
            >
              <Grid
                item
                xs="auto"
                className="journeyText"
                component={Link}
                to={"/"}
              >
                {translations.pageTitles.HomepageTitle}
              </Grid>
              <Grid item xs="auto">
                &nbsp; &nbsp;{" "}
                <KeyboardArrowRightIcon
                  style={{ color: "#2B2B2B", fontSize: "15px" }}
                />{" "}
                &nbsp; &nbsp;
              </Grid>

              <Grid item xs="auto" className="journeyText">
                {translations.pageTitles.SitemappageTitle}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="section_style mt90">
          <Cloud
            lang={lang}
            color={darkMode && "#10214B"}
            myClass="top_section_cloud1 hide_for_tab_and_smaller_view"
            // rtlClass="top_cloud1_for_rtl"
          />

          <Triangle
            lang={lang}
            color={darkMode ? "#10214B" : "white"}
            myClass="top_section_triangle1 hide_for_tab_and_smaller_view"
            // rtlClass="top_triangle2_for_rtl"
          />
          <GlowBG
            lang={lang}
            color={darkMode ? "#005DB2" : "#113CFC"}
            myClass="section_top_GlowBG1"
            // rtlClass="top_GlowBG1_for_rtl"
          />
          <GlowBG
            lang={lang}
            color={darkMode && "#005DB2"}
            myClass="section_top_GlowBG2"
            // rtlClass="top_GlowBG2_for_rtl"
          />
          <div className="upper_slide">
            <Container maxWidth="lg">
              <h1
                className={`title_large2 mb8 center ${darkMode && "lightBlue"}`}
              >
                {translations.sitemap.title}
              </h1>
              <p
                className="text_body_small_regular fw400 center mb50"
                style={{ textTransform: "uppercase" }}
              >
                {translations.sitemap.subtitle}
              </p>

              <h4
                className={`title_semibold_medium mb20 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.sitemap.pagesTitle}
              </h4>
              <Grid
                container
                rowSpacing={2}
                style={{ paddingLeft: "20px" }}
                className="mb30"
              >
                {translations.sitemap.pages?.map((item, i) => (
                  <Grid item xs={12} sm={4} md={2.4} key={i}>
                    <Grid container alignItems="center" columnSpacing={2}>
                      <Grid item xs={1.5} sm="auto">
                        <div
                          style={{
                            width: "5px",
                            height: "5px",
                            borderRadius: "50%",
                            background: darkMode ? "#fff" : "#525252",
                          }}
                        ></div>
                      </Grid>
                      <Grid item xs={10.5} sm="auto">
                        <p
                          className={`text_body_medium fw400   ${
                            darkMode ? "white" : "black2"
                          }`}
                        >
                          {item}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <h4
                className={`title_semibold_medium mb20 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.sitemap.servicesTitle}
              </h4>
              <Grid
                container
                rowSpacing={2}
                style={{ paddingLeft: "20px" }}
                className="mb20"
              >
                {translations.sitemap.services?.map((item, i) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={i}>
                    <Grid container alignItems="center" columnSpacing={2}>
                      <Grid item xs={1.5} sm="auto">
                        <div
                          style={{
                            width: "5px",
                            height: "5px",
                            borderRadius: "50%",
                            background: darkMode ? "#fff" : "#525252",
                          }}
                        ></div>
                      </Grid>
                      <Grid item xs={10.5} sm="auto">
                        <p
                          className={`text_body_medium fw400   ${
                            darkMode ? "white" : "black2"
                          }`}
                        >
                          {item}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <h4
                className={`title_semibold_medium mb20 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.sitemap.productsTitle}
              </h4>
              <Grid
                container
                rowSpacing={2}
                style={{ paddingLeft: "20px" }}
                className="mb20"
              >
                {translations.sitemap.products?.map((item, i) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={i}>
                    <Grid container alignItems="center" columnSpacing={2}>
                      <Grid item xs={1.5} sm="auto">
                        <div
                          style={{
                            width: "5px",
                            height: "5px",
                            borderRadius: "50%",
                            background: darkMode ? "#fff" : "#525252",
                          }}
                        ></div>
                      </Grid>
                      <Grid item xs={10.5} sm="auto">
                        <p
                          className={`text_body_medium fw400   ${
                            darkMode ? "white" : "black2"
                          }`}
                        >
                          {item}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {/* <h4
                className={`title_semibold_medium mb20 ${
                  darkMode ? "white" : "black"
                }`}
              >
                {translations.sitemap.CaseStudiesTitle}
              </h4>
              <Grid
                container
                rowSpacing={2}
                style={{ paddingLeft: "20px" }}
                className="mb20"
              >
                {translations.sitemap.caseStudies?.map((item, i) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={i}>
                    <Grid container alignItems="center" columnSpacing={2}>
                      <Grid item xs={1.5} sm="auto">
                        <div
                          style={{
                            width: "5px",
                            height: "5px",
                            borderRadius: "50%",
                            background: darkMode ? "#fff" : "#525252",
                          }}
                        ></div>
                      </Grid>
                      <Grid item xs={10.5} sm="auto">
                        <p
                          className={`text_body_medium fw400   ${
                            darkMode ? "white" : "black2"
                          }`}
                        >
                          {item}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid> */}
            </Container>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Sitemap;
