import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import "./Header.css";
import { Grid, ListItemIcon, Menu, MenuItem, TextField } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MobileDrawer from "./MobileDrawer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SendIcon from "../svg-icons/SendIcon";
import axios from "axios";
import { useSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";
const Header = ({ translations, lang, setLang, darkMode, setDarkMode }) => {
  // const { window } = props;
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("location", location.pathname);
  const [serveOpen, setServeOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openLanguage = Boolean(anchorEl);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLanguageClose = () => {
    setAnchorEl(null);
  };
  const [languageButtonValue, setLanguageButtonValue] = useState(
    translations.header.english
  );

  const checkServeOpen = () => {
    let serveMenus = ["/startup", "/smb", "/enterprise"];
    if (serveMenus.includes(location.pathname)) {
      setServeOpen(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const clearForm = () => {
    console.log("asdasdas");
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // let err = validation();

    // if (err) {
    //   return;
    // } else {
    setLoading(true);
    try {
      let response = await axios({
        url: `/api/v1/contact-messages`,
        method: "post",
        data: { name, email, message },
      });
      console.log("response", response);
      // handleSnakbarOpen(response.data.messages, "success");
      if (response.status === 200) {
        clearForm();
        handleClose();
        handleSnakbarOpen(response.data.messages.toString(), "success");
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);

      handleSnakbarOpen("someting wrong", "error");
    }

    setLoading(false);
    // }
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  useEffect(() => {
    checkServeOpen();
  }, []);

  return (
    <>
      <div id="header" className="appbar_holder">
        <AppBar position="sticky" className="appbar_style">
          <Toolbar
            role="navigation"
            aria-label="Main menu"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Box
              component="div"
              sx={{ flexGrow: 1, display: { xs: "block", md: "block" } }}
            >
              <Link to={"/"}>
                <img
                  src="/images/logo.png"
                  alt="logo"
                  className="header_logo_style"
                />
              </Link>
            </Box>
            <Box
              sx={{
                display: { xs: "block", sm: "block", md: "block", lg: "none" },
              }}
            >
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openLanguage}
                onClose={handleLanguageClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleLanguageClose();
                    setLang("en");
                    setLanguageButtonValue(translations.header.english);
                  }}
                >
                  &nbsp;&nbsp;{translations.header.english} &nbsp; &nbsp;
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleLanguageClose();
                    setLang("kr");
                    setLanguageButtonValue(translations.header.kurdish);
                  }}
                >
                  {" "}
                  &nbsp;&nbsp;{translations.header.kurdish} &nbsp;&nbsp;
                </MenuItem>
              </Menu>

              {/* <Button
                className={`nav_button ${darkMode && "nav_button_dark_color"} ${
                  lang === "kr" && "button_start_icon"
                }`}
                sx={{
                  display: { xs: "none", sm: "inline-flex" },
                }}
                startIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="20"
                    viewBox="0 0 23 20"
                    fill="none"
                  >
                    <rect
                      x="1.26758"
                      y="3.69531"
                      width="21.2322"
                      height="14.9096"
                      rx="1.5"
                      fill="white"
                      stroke="#113CFC"
                    />
                    <path
                      d="M1.26758 9.47917V9.97917H1.76758H9.13566V11.4146C9.13566 12.2431 9.80723 12.9146 10.6357 12.9146H13.1317C13.9601 12.9146 14.6317 12.2431 14.6317 11.4146V9.97917H21.9998H22.4998V9.47917V5.19531C22.4998 4.36689 21.8282 3.69531 20.9998 3.69531H2.76758C1.93915 3.69531 1.26758 4.36688 1.26758 5.19531V9.47917Z"
                      fill="#FFC93C"
                      stroke="#113CFC"
                    />
                    <path
                      d="M6.95801 2.93193V3.43193H7.45801H15.6773H16.1773V2.93193V2.03516C16.1773 1.20673 15.5058 0.535156 14.6773 0.535156H8.45801C7.62958 0.535156 6.95801 1.20673 6.95801 2.03516V2.93193Z"
                      stroke="#113CFC"
                    />
                  </svg>
                }
                onClick={handleClickOpen}
              >
                {translations.header.anyBusinessIdea}
              </Button> */}
              <IconButton
                aria-label="theme change"
                // style={{ marginRight: "16px", background: "#113CFC" }}
                className={
                  darkMode
                    ? `mode_button mode_button_dark_color`
                    : `mode_button`
                }
                onClick={() => setDarkMode(!darkMode)}
              >
                {darkMode ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_1160_14018"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_1160_14018)">
                      <path
                        d="M1 15V13H5V15H1ZM6.35 9.75L3.525 6.925L4.925 5.5L7.75 8.35L6.35 9.75ZM7 18V15H17V18H7ZM11 7V2H13V7H11ZM17.65 9.75L16.25 8.35L19.075 5.525L20.5 6.925L17.65 9.75ZM19 15V13H23V15H19Z"
                        fill="#FF7539"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <mask
                      id="mask0_1860_3981"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="25"
                    >
                      <rect
                        y="0.570312"
                        width="24"
                        height="24"
                        fill="#D9D9D9"
                      />
                    </mask>
                    <g mask="url(#mask0_1860_3981)">
                      <path
                        d="M12 21.0703C9.63109 21.0703 7.63372 20.2469 5.97855 18.5918C4.32339 16.9366 3.5 14.9392 3.5 12.5703C3.5 10.2014 4.32339 8.20403 5.97855 6.54887C7.63372 4.8937 9.63109 4.07031 12 4.07031C12.0483 4.07031 12.0965 4.07069 12.1444 4.07144C11.8062 4.44231 11.5147 4.86055 11.2701 5.32474C10.8229 6.17362 10.6 7.09141 10.6 8.07031C10.6 9.70141 11.1766 11.104 12.3214 12.2489C13.4663 13.3937 14.8689 13.9703 16.5 13.9703C17.494 13.9703 18.417 13.7481 19.26 13.2991C19.718 13.0552 20.1314 12.7648 20.4989 12.4282C20.4996 12.4754 20.5 12.5227 20.5 12.5703C20.5 14.9392 19.6766 16.9366 18.0214 18.5918C16.3663 20.2469 14.3689 21.0703 12 21.0703Z"
                        stroke="#113CFC"
                      />
                    </g>
                  </svg>
                )}
              </IconButton>

              <MobileDrawer
                translations={translations}
                lang={lang}
                setLang={setLang}
                darkMode={darkMode}
                setDarkMode={setDarkMode}
                languageButtonValue={languageButtonValue}
                setLanguageButtonValue={setLanguageButtonValue}
              />
            </Box>
            <Box
              role="nav"
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
              }}
            >
              <Button
                component={Link}
                to="/"
                className={
                  darkMode
                    ? `nav_item nav_item_dark_color ${
                        location.pathname === "/" && "dark_active"
                      }`
                    : `nav_item ${location.pathname === "/" && "active"}`
                }
              >
                {translations.header.home}
              </Button>

              <Button
                component={Link}
                to="/services"
                className={
                  darkMode
                    ? `nav_item nav_item_dark_color ${
                        location.pathname === "/services" && "dark_active"
                      }`
                    : `nav_item ${
                        location.pathname === "/services" && "active"
                      }`
                }
              >
                {translations.header.services}
              </Button>
              <Button
                component={Link}
                to="/products"
                className={
                  darkMode
                    ? `nav_item nav_item_dark_color ${
                        location.pathname === "/products" && "dark_active"
                      }`
                    : `nav_item ${
                        location.pathname === "/products" && "active"
                      }`
                }
              >
                {translations.header.products}
              </Button>
              {/* <Button
                component={Link}
                to="/case-studies"
                className={
                  darkMode
                    ? `nav_item nav_item_dark_color ${
                        location.pathname === "/case-studies" && "dark_active"
                      }`
                    : `nav_item ${
                        location.pathname === "/case-studies" && "active"
                      }`
                }
              >
                {translations.header.caseStudies}
              </Button> */}
              <Button
                component={Link}
                to="/company"
                className={
                  darkMode
                    ? `nav_item nav_item_dark_color ${
                        location.pathname === "/company" && "dark_active"
                      }`
                    : `nav_item ${location.pathname === "/company" && "active"}`
                }
              >
                {translations.header.company}
              </Button>
              {/* <Button
                component={Link}
                to="/career"
                className={`nav_item ${
                  location.pathname === "/career" && "active"
                }`}
              >
                {translations.header.career}
              </Button> */}
              <Button
                component={Link}
                to="/contact"
                className={
                  darkMode
                    ? `nav_item nav_item_dark_color ${
                        location.pathname === "/contact" && "dark_active"
                      }`
                    : `nav_item ${location.pathname === "/contact" && "active"}`
                }
              >
                {translations.header.contact}
              </Button>

              <Button
                className={`nav_button ${darkMode && "nav_button_dark_color"} ${
                  lang === "kr" && "nav_button_endIcon"
                }`}
                endIcon={<KeyboardArrowDownIcon />}
                id="basic-button"
                aria-controls={openLanguage ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openLanguage ? "true" : undefined}
                onClick={handleLanguageClick}
              >
                {languageButtonValue}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openLanguage}
                onClose={handleLanguageClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleLanguageClose();
                    setLang("en");
                    setLanguageButtonValue(translations.header.english);
                  }}
                >
                  &nbsp;&nbsp;{translations.header.english} &nbsp; &nbsp;
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleLanguageClose();
                    setLang("kr");
                    setLanguageButtonValue(translations.header.kurdish);
                  }}
                >
                  {" "}
                  &nbsp;&nbsp;{translations.header.kurdish} &nbsp;&nbsp;
                </MenuItem>
              </Menu>

              <Button
                className={`nav_button ${darkMode && "nav_button_dark_color"} ${
                  lang === "kr" && "button_start_icon"
                }`}
                startIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="20"
                    viewBox="0 0 23 20"
                    fill="none"
                  >
                    <rect
                      x="1.26758"
                      y="3.69531"
                      width="21.2322"
                      height="14.9096"
                      rx="1.5"
                      fill="white"
                      stroke="#113CFC"
                    />
                    <path
                      d="M1.26758 9.47917V9.97917H1.76758H9.13566V11.4146C9.13566 12.2431 9.80723 12.9146 10.6357 12.9146H13.1317C13.9601 12.9146 14.6317 12.2431 14.6317 11.4146V9.97917H21.9998H22.4998V9.47917V5.19531C22.4998 4.36689 21.8282 3.69531 20.9998 3.69531H2.76758C1.93915 3.69531 1.26758 4.36688 1.26758 5.19531V9.47917Z"
                      fill="#FFC93C"
                      stroke="#113CFC"
                    />
                    <path
                      d="M6.95801 2.93193V3.43193H7.45801H15.6773H16.1773V2.93193V2.03516C16.1773 1.20673 15.5058 0.535156 14.6773 0.535156H8.45801C7.62958 0.535156 6.95801 1.20673 6.95801 2.03516V2.93193Z"
                      stroke="#113CFC"
                    />
                  </svg>
                }
                onClick={handleClickOpen}
              >
                {translations.header.anyBusinessIdea}
              </Button>
              <IconButton
                // style={{ marginRight: "16px", background: "#113CFC" }}
                aria-label="theme change"
                className={
                  darkMode
                    ? `mode_button mode_button_dark_color`
                    : `mode_button`
                }
                onClick={() => setDarkMode(!darkMode)}
              >
                {darkMode ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_1160_14018"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_1160_14018)">
                      <path
                        d="M1 15V13H5V15H1ZM6.35 9.75L3.525 6.925L4.925 5.5L7.75 8.35L6.35 9.75ZM7 18V15H17V18H7ZM11 7V2H13V7H11ZM17.65 9.75L16.25 8.35L19.075 5.525L20.5 6.925L17.65 9.75ZM19 15V13H23V15H19Z"
                        fill="#FF7539"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <mask
                      id="mask0_1860_3981"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="25"
                    >
                      <rect
                        y="0.570312"
                        width="24"
                        height="24"
                        fill="#D9D9D9"
                      />
                    </mask>
                    <g mask="url(#mask0_1860_3981)">
                      <path
                        d="M12 21.0703C9.63109 21.0703 7.63372 20.2469 5.97855 18.5918C4.32339 16.9366 3.5 14.9392 3.5 12.5703C3.5 10.2014 4.32339 8.20403 5.97855 6.54887C7.63372 4.8937 9.63109 4.07031 12 4.07031C12.0483 4.07031 12.0965 4.07069 12.1444 4.07144C11.8062 4.44231 11.5147 4.86055 11.2701 5.32474C10.8229 6.17362 10.6 7.09141 10.6 8.07031C10.6 9.70141 11.1766 11.104 12.3214 12.2489C13.4663 13.3937 14.8689 13.9703 16.5 13.9703C17.494 13.9703 18.417 13.7481 19.26 13.2991C19.718 13.0552 20.1314 12.7648 20.4989 12.4282C20.4996 12.4754 20.5 12.5227 20.5 12.5703C20.5 14.9392 19.6766 16.9366 18.0214 18.5918C16.3663 20.2469 14.3689 21.0703 12 21.0703Z"
                        stroke="#113CFC"
                      />
                    </g>
                  </svg>
                )}
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          className="demo_dialog"
        >
          <DialogContent
            style={{ padding: "0px", background: darkMode && "#000513" }}
          >
            <Grid container>
              <Grid
                item
                md={12}
                lg={5}
                className={`demo_dialog_left ${
                  darkMode && "demo_dialog_left_dark"
                } hide_for_tab_and_smaller_view`}
                style={{ position: "relative" }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid item> </Grid>
                  <Grid item style={{ padding: "20px" }}>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        xs={6}
                        style={{ borderRight: "1px solid #FF7539" }}
                      >
                        {" "}
                        <img
                          src="/images/newroz_icon_white.png"
                          alt="logo"
                          style={{ width: "200px" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a
                          // className={classes.button}
                          // href="https://drive.google.com/file/d/17vtjYpam6cbCyTaZYz1MmHedR3wJSYJp/view?usp=sharing"
                          // target="_blank"
                          href="/newroz _technology_company_profile .pdf"
                          download
                        >
                          <Button
                            endIcon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                              >
                                <mask
                                  id="mask0_621_3780"
                                  style={{ maskType: "alpha" }}
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="20"
                                  height="21"
                                >
                                  <rect
                                    y="0.5"
                                    width="20"
                                    height="20"
                                    fill="#D9D9D9"
                                  />
                                </mask>
                                <g mask="url(#mask0_621_3780)">
                                  <path
                                    d="M9.99935 14.224C9.86046 14.224 9.73025 14.2023 9.60872 14.1589C9.4872 14.1155 9.37435 14.0417 9.27018 13.9376L5.52018 10.1876C5.32921 9.99661 5.23372 9.75355 5.23372 9.45841C5.23372 9.16327 5.32921 8.92022 5.52018 8.72925C5.71115 8.53828 5.95855 8.43845 6.26237 8.42977C6.56619 8.42109 6.81358 8.51223 7.00456 8.70321L8.95768 10.6563V3.20841C8.95768 2.91328 9.05751 2.66588 9.25716 2.46623C9.45681 2.26657 9.70421 2.16675 9.99935 2.16675C10.2945 2.16675 10.5419 2.26657 10.7415 2.46623C10.9412 2.66588 11.041 2.91328 11.041 3.20841V10.6563L12.9941 8.70321C13.1851 8.51223 13.4325 8.42109 13.7363 8.42977C14.0401 8.43845 14.2875 8.53828 14.4785 8.72925C14.6695 8.92022 14.765 9.16327 14.765 9.45841C14.765 9.75355 14.6695 9.99661 14.4785 10.1876L10.7285 13.9376C10.6243 14.0417 10.5115 14.1155 10.39 14.1589C10.2684 14.2023 10.1382 14.224 9.99935 14.224ZM3.74935 18.8334C3.17643 18.8334 2.68598 18.6294 2.27799 18.2214C1.87001 17.8134 1.66602 17.323 1.66602 16.7501V14.6667C1.66602 14.3716 1.76584 14.1242 1.96549 13.9246C2.16515 13.7249 2.41254 13.6251 2.70768 13.6251C3.00282 13.6251 3.25022 13.7249 3.44987 13.9246C3.64952 14.1242 3.74935 14.3716 3.74935 14.6667V16.7501H16.2493V14.6667C16.2493 14.3716 16.3492 14.1242 16.5488 13.9246C16.7485 13.7249 16.9959 13.6251 17.291 13.6251C17.5862 13.6251 17.8335 13.7249 18.0332 13.9246C18.2329 14.1242 18.3327 14.3716 18.3327 14.6667V16.7501C18.3327 17.323 18.1287 17.8134 17.7207 18.2214C17.3127 18.6294 16.8223 18.8334 16.2493 18.8334H3.74935Z"
                                    fill="#FF7539"
                                  />
                                </g>
                              </svg>
                            }
                            style={{
                              fontWeight: 600,
                              color: darkMode ? "#fff" : "#7C3E23",
                            }}
                          >
                            Company Profile
                          </Button>
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <p
                  className="demo_form_title center"
                  style={{ marginTop: "80px" }}
                >
                  Request a Demo?
                </p>
                <p className="text_body_medium center">
                  Fill it up. We will contact you
                </p> */}
                {/* <div
                  style={{ position: "absolute", bottom: 0, padding: "20px" }}
                >
                  <img
                    src="/images/newroz_icon_white.png"
                    alt="logo"
                    style={{ width: "140px", marginRight: "20px" }}
                  />

                  <Button
                    style={{ marginBottom: "20px" }}
                    endIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <mask
                          id="mask0_621_3780"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="20"
                          height="21"
                        >
                          <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_621_3780)">
                          <path
                            d="M9.99935 14.224C9.86046 14.224 9.73025 14.2023 9.60872 14.1589C9.4872 14.1155 9.37435 14.0417 9.27018 13.9376L5.52018 10.1876C5.32921 9.99661 5.23372 9.75355 5.23372 9.45841C5.23372 9.16327 5.32921 8.92022 5.52018 8.72925C5.71115 8.53828 5.95855 8.43845 6.26237 8.42977C6.56619 8.42109 6.81358 8.51223 7.00456 8.70321L8.95768 10.6563V3.20841C8.95768 2.91328 9.05751 2.66588 9.25716 2.46623C9.45681 2.26657 9.70421 2.16675 9.99935 2.16675C10.2945 2.16675 10.5419 2.26657 10.7415 2.46623C10.9412 2.66588 11.041 2.91328 11.041 3.20841V10.6563L12.9941 8.70321C13.1851 8.51223 13.4325 8.42109 13.7363 8.42977C14.0401 8.43845 14.2875 8.53828 14.4785 8.72925C14.6695 8.92022 14.765 9.16327 14.765 9.45841C14.765 9.75355 14.6695 9.99661 14.4785 10.1876L10.7285 13.9376C10.6243 14.0417 10.5115 14.1155 10.39 14.1589C10.2684 14.2023 10.1382 14.224 9.99935 14.224ZM3.74935 18.8334C3.17643 18.8334 2.68598 18.6294 2.27799 18.2214C1.87001 17.8134 1.66602 17.323 1.66602 16.7501V14.6667C1.66602 14.3716 1.76584 14.1242 1.96549 13.9246C2.16515 13.7249 2.41254 13.6251 2.70768 13.6251C3.00282 13.6251 3.25022 13.7249 3.44987 13.9246C3.64952 14.1242 3.74935 14.3716 3.74935 14.6667V16.7501H16.2493V14.6667C16.2493 14.3716 16.3492 14.1242 16.5488 13.9246C16.7485 13.7249 16.9959 13.6251 17.291 13.6251C17.5862 13.6251 17.8335 13.7249 18.0332 13.9246C18.2329 14.1242 18.3327 14.3716 18.3327 14.6667V16.7501C18.3327 17.323 18.1287 17.8134 17.7207 18.2214C17.3127 18.6294 16.8223 18.8334 16.2493 18.8334H3.74935Z"
                            fill="#FF7539"
                          />
                        </g>
                      </svg>
                    }
                  >
                    Company Profile
                  </Button>
                </div> */}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={7}
                style={{ position: "relative" }}
                className="form_padding"
              >
                <IconButton
                  onClick={handleClose}
                  className={`demo_form_close_button ${darkMode && "white"}`}
                >
                  <ClearIcon />
                </IconButton>
                <p
                  className={`subtitle mb6 ${darkMode && "white"}`}
                  // style={{ marginTop: "60px" }}
                >
                  {lang === "kr" ? "ابقى على تواصل" : " Get In Touch"}
                </p>
                <p
                  className={`text_body_medium mb30 ${darkMode && "gray"}`}
                  style={{ fontSize: "16px" }}
                >
                  {lang === "kr"
                    ? "نحن هنا لخدمتك. لا تتردد في مشاركة أفكارك."
                    : " We are here to serve you. Feel free to share your ideas."}
                </p>
                <form onSubmit={onSubmit}>
                  <Grid
                    container
                    spacing={3}
                    // className="message_box_input_box_margin"
                  >
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        placeholder={translations.homeContact.formTextYourName}
                        size="small"
                        id="outlined-basic"
                        variant="outlined"
                        className={`message_form_input_style2 ${
                          darkMode && "message_form_input_style2_dark"
                        }`}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        placeholder={translations.homeContact.formTextEmail}
                        type="email"
                        size="small"
                        id="outlined-basic"
                        variant="outlined"
                        className={`message_form_input_style2 ${
                          darkMode && "message_form_input_style2_dark"
                        }`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        placeholder={translations.homeContact.formTextPhone}
                        size="small"
                        id="outlined-basic"
                        variant="outlined"
                        className={`message_form_input_style2 ${
                          darkMode && "message_form_input_style2_dark"
                        }`}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        placeholder={
                          translations.homeContact.formTextYourMassage
                        }
                        size="small"
                        id="outlined-basic"
                        variant="outlined"
                        className={`message_form_input_style2 ${
                          darkMode && "message_form_input_style2_dark"
                        } message_form_input_padding_style3  `}
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} className="">
                      <Button
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        startIcon={
                          <SendIcon
                            lang={lang}
                            color={darkMode && "#1C1C1C"}
                            // myClass="Magicwand"
                            // rtlClass="send_icon_for_rtl"
                          />
                        }
                        className={`top_contained_buttton ${
                          darkMode && "black"
                        }`}
                        disableElevation
                        // onClick={onSubmit}
                        type="submit"
                        style={{ minWidth: "195px" }}
                      >
                        {loading === false && translations.buttonText.submitNow}
                        {/* {translations.buttonText.submitNow} */}
                        <PulseLoader
                          color={"#FF7539"}
                          loading={loading}
                          size={10}
                          speedMultiplier={0.5}
                        />{" "}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default Header;
