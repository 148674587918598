import React from "react";

const ThreeCircle = ({ lang, color, myClass, rtlClass }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="314"
      // height="256"
      viewBox="0 0 314 256"
      fill="none"
      className={`${myClass} ${lang === "kr" && rtlClass}`}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.998 207.518C91.7469 210.323 95.4059 213.1 98.9888 215.819C125.71 236.097 148.205 253.168 172.314 254.97C199.656 257.014 229.002 239.493 256.488 215.767C283.901 192.041 309.382 162.11 312.961 128.602C316.468 95.0206 298.073 57.8622 270.66 39.1005C249.587 24.7164 223.256 21.1885 192.505 17.0684C183.146 15.8145 173.378 14.5058 163.224 12.8194C156.251 11.6613 149.095 10.325 141.864 8.97471C103.971 1.89858 64.0093 -5.5639 37.3223 10.1913C5.61416 28.9531 -7.19791 80.5661 5.39944 119.258C17.0884 155.024 50.4688 179.728 80.5237 201.971C83.0434 203.835 85.5397 205.683 87.998 207.518ZM7.25758 118.65C13.4511 137.601 25.9038 153.468 40.8744 167.606C53.5399 179.567 67.8961 190.192 81.6658 200.384C84.1907 202.252 86.696 204.106 89.1674 205.952L89.1691 205.953C92.9487 208.781 96.6271 211.572 100.221 214.3C111.773 223.066 122.456 231.173 132.836 237.721C146.424 246.294 159.255 252.033 172.46 253.021C198.949 255.001 227.756 237.987 255.209 214.289C282.564 190.613 307.523 161.111 311.017 128.394C314.443 95.5685 296.41 59.0928 269.555 40.7137C256.143 31.559 240.477 26.8589 222.599 23.5569C213.662 21.9061 204.215 20.6115 194.245 19.274C193.579 19.1846 192.911 19.0951 192.241 19.0053C182.887 17.752 173.09 16.4395 162.904 14.7479C155.846 13.5757 148.644 12.2307 141.391 10.8759C126.108 8.02151 110.591 5.12347 95.6862 3.70556C73.7247 1.61641 53.6547 2.82004 38.3177 11.8737C23.0313 20.9187 12.1342 38.0032 6.62954 57.7751C1.13022 77.5276 1.09065 99.7076 7.25758 118.65Z"
        fill={color || "#FFC93C"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M97.965 189.895C101.009 192.169 103.981 194.419 106.89 196.622C128.59 213.056 146.858 226.892 166.436 228.352C188.64 230.009 212.472 215.809 234.792 196.581C257.054 177.352 277.746 153.094 280.652 125.938C283.5 98.7217 268.562 68.6067 246.3 53.4013C229.188 41.7437 207.805 38.8846 182.833 35.5454C175.233 34.5292 167.3 33.4685 159.055 32.1018C153.391 31.1632 147.58 30.0803 141.708 28.9859C110.936 23.2511 78.4843 17.2031 56.8123 29.9719C31.0629 45.1773 20.6585 87.007 30.8885 118.364C40.3809 147.351 67.4884 167.372 91.8953 185.399C93.9415 186.91 95.9687 188.408 97.965 189.895ZM32.7897 117.744C37.7892 133.009 47.8477 145.812 59.9787 157.245C70.2444 166.92 81.8797 175.514 93.0622 183.774C95.1137 185.29 97.1501 186.794 99.1599 188.291L99.1616 188.293C102.237 190.589 105.229 192.855 108.15 195.067C117.527 202.169 126.183 208.724 134.596 214.021C145.607 220.953 155.959 225.565 166.585 226.358C187.918 227.949 211.198 214.267 233.484 195.067C255.687 175.889 275.844 152.071 278.664 125.725C281.429 99.2841 266.862 69.8677 245.172 55.0528C234.35 47.6809 221.693 43.885 207.197 41.2129C199.951 39.8771 192.289 38.8291 184.192 37.745C183.65 37.6725 183.107 37.5999 182.562 37.527C174.967 36.5115 167.005 35.4469 158.727 34.0749C152.978 33.122 147.12 32.0301 141.225 30.9312C128.824 28.6197 116.256 26.2768 104.17 25.1294C86.356 23.4382 70.1673 24.4251 57.8285 31.6945C45.5349 38.9544 36.7359 52.6924 32.2845 68.6486C27.8384 84.5862 27.8095 102.478 32.7897 117.744Z"
        fill={color || "#FFC93C"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M91.3466 197.743C94.7507 200.291 98.073 202.814 101.326 205.284C125.589 223.706 146.014 239.215 167.905 240.853C192.732 242.71 219.378 226.792 244.334 205.237C269.225 183.682 292.362 156.489 295.611 126.047C298.796 95.5381 282.093 61.7795 257.202 44.7344C238.069 31.6664 214.16 28.4613 186.238 24.7182C177.741 23.579 168.871 22.39 159.651 20.858C153.319 19.8058 146.822 18.5918 140.257 17.3651C105.85 10.9364 69.5651 4.15667 45.3335 18.4703C16.5427 35.5155 4.90947 82.4061 16.3478 117.558C26.9613 150.051 57.2705 172.495 84.5601 192.703C86.848 194.397 89.1146 196.075 91.3466 197.743ZM18.4735 116.861C24.0636 133.974 35.31 148.326 48.8738 161.142C60.352 171.988 73.3615 181.622 85.8648 190.881C88.1587 192.58 90.4355 194.266 92.6827 195.945L92.6846 195.946C96.1237 198.521 99.4683 201.06 102.735 203.54C113.219 211.501 122.897 218.849 132.304 224.787C144.616 232.559 156.19 237.728 168.072 238.617C191.924 240.401 217.953 225.063 242.872 203.54C267.698 182.042 290.235 155.342 293.388 125.809C296.479 96.1686 280.193 63.1931 255.941 46.5858C243.84 38.3219 229.689 34.0668 213.481 31.0714C205.378 29.574 196.811 28.3992 187.758 27.1839C187.152 27.1027 186.545 27.0213 185.936 26.9396C177.444 25.8012 168.542 24.6078 159.286 23.0698C152.857 22.0016 146.308 20.7776 139.716 19.5457C125.851 16.9545 111.798 14.3282 98.2842 13.0419C78.3665 11.1461 60.2659 12.2524 46.4697 20.4014C32.7241 28.5396 22.8858 43.9397 17.9087 61.8265C12.9374 79.6924 12.9051 99.7484 18.4735 116.861Z"
        fill={color || "#FFC93C"}
      />
    </svg>
  );
};

export default ThreeCircle;
