import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Container, Grid, TextField } from "@mui/material";
import SendIcon from "../svg-icons/SendIcon";
import { useSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";
const ContactForm = ({ translations, lang, darkMode, buttonLeft }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const clearForm = () => {
    console.log("asdasdas");
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // let err = validation();

    // if (err) {
    //   return;
    // } else {
    setLoading(true);
    try {
      let response = await axios({
        url: `/api/v1/contact-messages`,
        method: "post",
        data: { name, email, message },
      });
      console.log("response", response);
      // handleSnakbarOpen(response.data.messages, "success");
      if (response.status === 200) {
        clearForm();
        handleSnakbarOpen(response.data.messages.toString(), "success");
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);

      handleSnakbarOpen("someting wrong", "error");
    }

    setLoading(false);
    // }
  };
  return (
    <div>
      {" "}
      <form onSubmit={onSubmit}>
        <Grid container spacing={3} className="message_box_input_box_margin">
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              placeholder={translations.homeContact.formTextYourName}
              size="small"
              id="outlined-basic"
              variant="outlined"
              // className="message_form_input_style"
              className={`message_form_input_style ${
                darkMode && "message_form_input_style_dark"
              }`}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="email"
              placeholder={translations.homeContact.formTextEmail}
              size="small"
              id="outlined-basic"
              variant="outlined"
              className={`message_form_input_style ${
                darkMode && "message_form_input_style_dark"
              }`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              placeholder={translations.homeContact.formTextPhone}
              size="small"
              id="outlined-basic"
              variant="outlined"
              className={`message_form_input_style ${
                darkMode && "message_form_input_style_dark"
              }`}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              placeholder={translations.homeContact.formTextYourMassage}
              size="small"
              id="outlined-basic"
              variant="outlined"
              className={`message_form_input_style message_form_input_padding_style3 ${
                darkMode && "message_form_input_style_dark"
              }`}
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} className={`${buttonLeft ? "" : "center"}`}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              startIcon={
                <SendIcon
                  lang={lang}
                  color={darkMode && "#1C1C1C"}
                  // myClass="Magicwand"
                  // rtlClass="send_icon_for_rtl"
                />
              }
              className={`top_contained_buttton ${darkMode && "black"}`}
              disableElevation
              // onClick={onSubmit}
              type="submit"
              style={{ minWidth: "195px" }}
            >
              {loading === false && translations.buttonText.submitNow}
              {/* {translations.buttonText.submitNow} */}
              <PulseLoader
                color={"#FF7539"}
                loading={loading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ContactForm;
