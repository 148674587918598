import React from "react";

const CommaIcon = ({ lang, myClass, rtlClass, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="31"
      viewBox="0 0 41 31"
      fill="none"
      className={`${myClass} ${lang === "kr" && rtlClass}`}
    >
      <path
        d="M0.186523 22.0061C0.186523 19.6494 0.684574 17.3564 1.68067 15.1271C2.67678 12.8341 3.9219 10.7641 5.41605 8.91692C6.96554 7.00609 8.68105 5.35004 10.5626 3.94876C12.4994 2.48379 14.381 1.3373 16.2071 0.509277L18.6144 4.52201C16.5668 6.30545 15.0174 7.89782 13.9659 9.29909C12.9698 10.7004 12.4718 12.1016 12.4718 13.5029C12.4718 15.7322 14.0213 18.1526 17.1202 20.7641C17.1202 22.2927 16.8712 23.6621 16.3732 24.8723C15.8751 26.0825 15.1834 27.1016 14.2979 27.9297C13.4679 28.7577 12.4994 29.3946 11.3927 29.8405C10.3412 30.2863 9.23444 30.5093 8.07232 30.5093C5.80342 30.5093 3.9219 29.8086 2.42775 28.4074C0.933599 27.0061 0.186523 24.8723 0.186523 22.0061ZM22.1837 22.0061C22.1837 19.6494 22.6818 17.3564 23.6779 15.1271C24.674 12.8341 25.9191 10.7641 27.4133 8.91692C28.9628 7.00609 30.6783 5.35004 32.5598 3.94876C34.4966 2.48379 36.3782 1.3373 38.2044 0.509277L40.5286 4.52201C38.5364 6.30545 37.0146 7.89782 35.9631 9.29909C34.967 10.7004 34.469 12.1016 34.469 13.5029C34.469 15.7322 36.0185 18.1526 39.1175 20.7641C39.1175 22.2927 38.8684 23.6621 38.3704 24.8723C37.8723 26.0825 37.1806 27.1016 36.2952 27.9297C35.4651 28.7577 34.4967 29.3946 33.3899 29.8405C32.2831 30.2863 31.1486 30.5093 29.9865 30.5093C27.773 30.5093 25.9191 29.8086 24.425 28.4074C22.9308 27.0061 22.1837 24.8723 22.1837 22.0061Z"
        fill={color || "#FFC93C"}
      />
    </svg>
  );
};

export default CommaIcon;
