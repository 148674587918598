import React, { useEffect, useState } from "react";
// import "../App.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MagicWand from "../svg-icons/MagicWand";
import Pattern from "../svg-icons/Pattern";
import CurveLine from "../svg-icons/CurveLine";
import Plane from "../svg-icons/Plane";
import Star from "../svg-icons/Star";
import Cloud from "../svg-icons/Cloud";
import Triangle from "../svg-icons/Triangle";
import GlowBG from "../svg-icons/GlowBG";
import { Circle, DarkMode, Transform } from "@mui/icons-material";
import BusinessIcon from "../svg-icons/BusinessIcon";
import CommaIcon from "../svg-icons/CommaIcon";
import SendIcon from "../svg-icons/SendIcon";
import { Link } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Collapse from "@mui/material/Collapse";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import OkIcon from "../svg-icons/OkIcon";
import Circle2 from "../svg-icons/Circle2";
import Square2 from "../svg-icons/Square2";
import ThreeCircle from "../svg-icons/ThreeCircle";

const NoPageFound = ({ translations, lang, darkMode }) => {
  const firstImage = "/images/Ewallet_login.png";

  const [expanded, setExpanded] = useState("");
  const [activeImage, setActiveImage] = useState(firstImage);

  const handleChange = (panel, img) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setActiveImage(img);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Container maxWidth="xl">
        <div
          className="bg_iamge"
          style={{
            position: "relative",
            textAlign: "center",
            minHeight: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GlowBG
            lang={lang}
            color={darkMode ? "#005DB2" : "#113CFC"}
            myClass="section_top_GlowBG1"
            // rtlClass="top_GlowBG1_for_rtl"
          />
          <GlowBG
            lang={lang}
            color={darkMode && "#005DB2"}
            myClass="section_top_GlowBG2"
            // rtlClass="top_GlowBG2_for_rtl"
          />
          <div className="upper_slide">
            <p className={`not_found ${darkMode && "lightBlue"}`}>404</p>
            <p
              className={`title_semibold_small mb12 ${
                darkMode ? "white" : "black"
              }`}
            >
              {lang === "kr"
                ? "أُووبس! لماذا أنت هنا؟"
                : "Oops! Why You’re Here?"}
            </p>
            <div class="detail_text_container">
              <p
                className={`text_body_medium fw400 mb24 ${
                  darkMode ? "white" : "black2"
                }`}
              >
                {lang === "kr"
                  ? "نحن نأسف جدا للإزعاج. يبدو أنك تحاول الوصول إلى صفحة تم حذفها أو لم تكن موجودة على الإطلاق."
                  : "  We are very sorry for inconvenience. It looks like you’re trying to access a page that either has been deleted or never existed."}
              </p>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={`top_contained_buttton ${darkMode && "black"}`}
              disableElevation
              component={Link}
              to="/"
            >
              {lang === "kr" ? "العودة إلى المنزل" : "    BACK TO HOME"}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NoPageFound;
