import React from "react";

const SendIcon = ({ lang, color, myClass, rtlClass }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={`${myClass} ${lang === "kr" && rtlClass}`}
    >
      <g clip-path="url(#clip0_975_5261)">
        <path
          d="M24.0315 1.39688C23.9888 1.58298 23.9615 1.77259 23.9021 1.9531C21.6515 8.94957 19.3987 15.9477 17.1435 22.9474C16.9273 23.6184 16.427 23.9969 15.7771 23.9969C15.1271 23.9969 14.6339 23.6191 14.4093 22.9474C13.3276 19.7085 12.249 16.4687 11.1734 13.2279C11.0985 13.0005 10.9803 12.8963 10.7606 12.8235C7.51051 11.7479 4.26205 10.6672 1.01522 9.58135C0.375046 9.36726 -0.00416339 8.85372 3.44917e-05 8.21284C0.00423238 7.57196 0.386934 7.06962 1.03551 6.86042C8.04877 4.60196 15.0623 2.3435 22.076 0.0850381C23.0772 -0.237499 24.0035 0.391484 24.0315 1.39688ZM21.596 3.66164L21.5499 3.64414C21.524 3.66653 21.4967 3.68752 21.4722 3.71411C18.5901 6.59526 15.7071 9.47547 12.8232 12.3548C12.6979 12.48 12.7147 12.5807 12.7616 12.7214C13.7322 15.6202 14.7013 18.52 15.6686 21.4208C15.6945 21.4984 15.7267 21.574 15.782 21.7216C17.7333 15.655 19.6643 9.65971 21.596 3.66164ZM20.3668 2.43935L20.3472 2.40227L2.27668 8.21984C2.39842 8.26741 2.4446 8.2898 2.49288 8.30379C5.43466 9.2833 8.37574 10.2663 11.3161 11.2528C11.498 11.3137 11.5841 11.2346 11.6918 11.1262C14.5235 8.29167 17.3559 5.45833 20.1891 2.62616C20.2499 2.56529 20.3073 2.50162 20.3668 2.43935Z"
          fill={color || "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_975_5261">
          <rect width="24.0322" height="24" fill={color || "white"} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendIcon;
